import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import for generating unique IDs
import { Button, Col, Container, Form, ListGroup, ListGroupItem, Row, Spinner } from 'react-bootstrap';
import './ChatHistoryStyle.css';
import { fetchWithSessionToken } from './session_http_helper';

const ChatbotNotifications = ({ shop }) => {
    const [notifications, setNotifications] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNotificationSettings = async () => {
            try {
                const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/live_chat/notifications/${shop}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch notification settings');
                }

                const data = await response.json();
                setNotifications(data || []); 
            } catch (error) {
                console.error('Failed to fetch notification settings:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotificationSettings();
    }, [shop]);

    const handleAddRow = () => {
        setNotifications([
            ...notifications,
            { id: uuidv4(), companyId: '', userName: '', phoneNumber: '', email: '' }
        ]);
    };

    const handleRemoveRow = (id) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
    };

    const handleChange = (id, value, field) => {
        setNotifications(notifications.map(notification =>
            notification.id === id ? { ...notification, [field]: value } : notification
        ));
    };

    const handleSendTestNotification = async (id) => {
        try {
            const notification = notifications.find(n => n.id === id);

            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/live_chat/notifications/${shop}/send_notification`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(notification)
            });

            if (!response.ok) {
                throw new Error('Failed to send test notification');
            }

            alert('Test notification sent successfully!');
        } catch (error) {
            console.error('Failed to send test notification:', error);
        }
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/live_chat/notifications/${shop}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(notifications)
            });

            if (!response.ok) {
                throw new Error('Failed to save notifications');
            }

            alert('Notifications saved successfully!');
        } catch (error) {
            console.error('Failed to save notifications:', error);
        } finally {
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return (
            <Container className="pt-5 pb-5 text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <Container className="pt-5 pb-5">
            <Row className="mt-4">
                <Col>
                    <h4>Notifications</h4>
                    <ListGroup>
                        {notifications.map(({ id, companyId, userName, phoneNumber, email }) => (
                            <ListGroupItem key={id}>
                                <Row>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter company"
                                            value={companyId}
                                            onChange={(e) => handleChange(id, e.target.value, 'companyId')}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter username"
                                            value={userName}
                                            onChange={(e) => handleChange(id, e.target.value, 'userName')}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={(e) => handleChange(id, e.target.value, 'phoneNumber')}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={(e) => handleChange(id, e.target.value, 'email')}
                                        />
                                    </Col>
                                    <Col md={3} className="text-right">
                                        <Button variant="warning" size="sm" onClick={() => handleSendTestNotification(id)}>
                                            <i className="fas fa-paper-plane"></i>
                                        </Button>{' '}
                                        <Button variant="danger" size="sm" onClick={() => handleRemoveRow(id)}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                    <Col md={9}></Col> {}
                    <Col md={3} className="text-right">
                        <Button
                            className="mt-3 mb-3"
                            onClick={handleAddRow}
                            variant="success"
                        >
                            <i className="fas fa-plus"></i> Add Row
                        </Button>
                    </Col>
                </Col>
                <Row className="mb-3">
                    <Col md={9}></Col>
                    <Col md={3} className="text-right">
                        <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};

export default ChatbotNotifications;
