import React, { useEffect, useState, Suspense } from 'react';
import { getToday, getThisWeek, getLastWeek, getThisMonth, getLastMonth, getThisYear } from '../dateUtils';
import useAuth from '../use_auth';
import LoadingIndicator from '../loading_dots';
import NavBarMenu from '../NavbarMenu';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup, Button, Form, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { fetchWithSessionToken } from "../session_http_helper";
import { Bar, Doughnut, Line, Chart } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'chart.js/auto';
import layoutStyles from "../assets/css/Layout.css";

export const links = () => {
  return [
    { rel: "stylesheet", href: layoutStyles }
  ];
};

export default function AdminDashboard() {
  const [protectedOrders, setProtectedOrders] = useState(0);
  const [nonProtectedOrders, setNonProtectedOrders] = useState(0);
  const [protectedAOV, setProtectedAOV] = useState(0);
  const [nonProtectedAOV, setNonProtectedAOV] = useState(0);
  const [revenueGenerated, setRevenueGenerated] = useState(0);
  const [clientPayout, setClientPayout] = useState(0);

  const [dailyAttachmentRates, setDailyAttachmentRates] = useState({});
  const [unReviewClaims, setUnReviewClaims] = useState(0);
  const [inReviewClaims, setInReviewClaims] = useState(0);
  const [approvedClaims, setApprovedClaims] = useState(0);
  const [deniedClaims, setDeniedClaims] = useState(0);
  const [eligibleOrdersFilter, setEligibleOrdersFilter] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useAuth('SUPER_ADMIN');

  const [protectedOrdersFilter, setProtectedOrdersFilter] = useState(false);
  const [repeatCustomersFilter, setRepeatCustomersFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [shop, setShop] = useState('getjovi.myshopify.com');
  const [barData, setBarData] = useState({});
  const [lineData, setLineData] = useState({});
  const [isBackfilling, setIsBackfilling] = useState(false);
  const [topAttachedProducts, setTopAttachedProducts] = useState([]);
  const [lowestAttachedProducts, setLowestAttachedProducts] = useState([]);

  const shopMapping = {
    'All': 'all',
    'Jovi': 'getjovi.myshopify.com',
    'Signal': 'signalrelief.myshopify.com',
    'BioLight': 'biolight-inc.myshopify.com',
    'SparksMotors': 'sparksmotors.myshopify.com',
    'DavesAuto': 'bcdaca-00.myshopify.com',
    'RobbyLayton': 'robbylaytonnation.myshopify.com',
    'MAGA': 'muskaf.myshopify.com',
    'WdAuto': '7uky0r-a1.myshopify.com',
    'Test': 'test-brella-ai.myshopify.com'
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Protection Revenue and Attachment Rate - Last 7 days',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      'y-revenue': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Revenue ($)',
        },
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
        },
      },
      'y-attachment': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Attachment Rate (%)',
        },
        grid: {
          drawOnChartArea: false, // Only want the grid lines for one axis to show up
        },
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
    },
  };

  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        maxBarThickness: 50,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
          borderDash: [5, 5],
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      title: {
        display: true,
        text: 'Attachment Rate',
        font: {
          size: 20,
          weight: 'bold',
        },
        color: '#333',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const productInfo = topAttachedProducts && topAttachedProducts[index]; // Ensure the array and index exist
            if (productInfo && productInfo.product) {
              return productInfo.product;
            }
          }
        }
      }
    },
    elements: {
      bar: {
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, '#4CAF50');
          gradient.addColorStop(1, '#388E3C');
          return gradient;
        },
        borderColor: '#4CAF50',
        borderWidth: 1,
        borderRadius: 8,
        hoverBackgroundColor: '#45a049',
        hoverBorderColor: '#3a923a',
        shadowOffsetX: 2,
        shadowOffsetY: 2,
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutBounce',
    },
  };

  const barOptionsLowest = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        maxBarThickness: 50,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
          borderDash: [5, 5],
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      title: {
        display: true,
        text: 'Attachment Rate',
        font: {
          size: 20,
          weight: 'bold',
        },
        color: '#333',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            return lowestAttachedProducts[index].product;
          }
        }
      }
    },
    elements: {
      bar: {
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, '#4CAF50');
          gradient.addColorStop(1, '#388E3C');
          return gradient;
        },
        borderColor: '#4CAF50',
        borderWidth: 1,
        borderRadius: 8,
        hoverBackgroundColor: '#45a049',
        hoverBorderColor: '#3a923a',
        shadowOffsetX: 2,
        shadowOffsetY: 2,
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutBounce',
    },
  };

  function updateClaims(claim) {
    return claim === 0 ? 0.1 : claim;
  }

  const doughnutData = {
    labels: ['New Claims', 'Resolved Claims', 'In Review Claims', 'Denied Claims'],
    datasets: [
      {
        data: [updateClaims(unReviewClaims), updateClaims(approvedClaims), updateClaims(inReviewClaims), updateClaims(deniedClaims)],
        backgroundColor: ['#007bff', '#17a2b8', '#6c757d', '#dc3545'],
        hoverBackgroundColor: ['#0056b3', '#117a8b', '#5a6268', '#c82333'],
        borderWidth: 2,
        hoverBorderColor: '#ffffff',
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    cutout: '70%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#666666',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const originalValue = context.raw;
            return `${context.label}: ${originalValue === 0.1 ? 0 : originalValue}`;
          },
        },
      },
      title: {
        display: true,
        text: 'Claims',
        color: '#333333',
        font: {
          size: 18,
          weight: 'bold',
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
  };

  const [date, setDate] = useState(() => {
    let storedDates;
    try { 
      storedDates = localStorage.getItem('dashboardDateRange');
    } catch (error) {}
    if (storedDates) {
      try {
        const parsedDates = JSON.parse(storedDates);
        if (Array.isArray(parsedDates) && 
            parsedDates.length === 2 && 
            parsedDates.every(dateStr => !isNaN(Date.parse(dateStr)))) {
          return parsedDates.map(dateStr => new Date(dateStr));
        }
      } catch (error) {
        console.error("Error while parsing stored date:", error);
      }
    }
    const today = new Date();
    return [today, today];
  });

  const handleEligibleOrdersChange = (event) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    console.log(isChecked);
    setEligibleOrdersFilter(isChecked);
    fetchOrders(date[0], date[1], isChecked);
  };

  const handleOutsideClick = (event) => {};

  const handleDateChange = (selectedDates) => {
    try {
      if (Array.isArray(selectedDates) && selectedDates.length === 2 && selectedDates.every(date => date instanceof Date && !isNaN(date.valueOf()))) {
        setDate(selectedDates);
      } else {
        console.error('Invalid date range selected:', selectedDates);
      }
    } catch (error) {
      console.error('Error handling date change:', error);
    }
  };  

  const handleRepeatCustomerChange = (event) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    setRepeatCustomersFilter(isChecked);
  };

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dateRange', JSON.stringify(date));
    } catch (error) {
      console.error('Failed to update session storage:', error);
    }
  }, [date]);

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dashboardDateRange', dateRangeString);
    } catch (error) {
      console.error('Error saving date range to localStorage:', error);
    }
  }, [date]);

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dashboardDateRange', dateRangeString);

      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    } catch (error) {
      console.error('Error in main useEffect:', error);
    }
  }, [date, isOpen]);

  useEffect(() => {
    if (shop) {
      fetchOrders(date[0], date[1], eligibleOrdersFilter);
    }
  }, [shop, date]);

  const backfillData = async (shop) => {
    try {
      setIsBackfilling(true);
      const url = `https://brella-protect-514098c560ec.herokuapp.com/companies/backfill/orders?shopId=${shop}`;

      await fetchWithSessionToken(url, {
        method: 'GET'
      });
      setIsBackfilling(false);

    } catch (error) {
      console.error('Failed to backfill data:', error);
      setIsBackfilling(false);
    }
  };

  const topProductsData = {
    labels: topAttachedProducts.map(product => 
        product.product.length > 10 ? `${product.product.slice(0, 10)}...` : product.product
    ),
    datasets: [
        {
            label: 'Top Attached Products',
            data: topAttachedProducts.map(product => product.attachmentRate),
            backgroundColor: '#4CAF50',
            borderColor: '#388E3C',
            borderWidth: 1,
        },
    ],
};

const lowestProductsData = {
    labels: lowestAttachedProducts.map(product => 
        product.product.length > 10 ? `${product.product.slice(0, 10)}...` : product.product
    ),
    datasets: [
        {
            label: 'Lowest Attached Products',
            data: lowestAttachedProducts.map(product => product.attachmentRate),
            backgroundColor: '#F44336',
            borderColor: '#D32F2F',
            borderWidth: 1,
        },
    ],
};

  const fetchOrders = async (startDate, endDate, eligibleOrdersFilter) => {
    try {
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      const url = `https://brella-protect-514098c560ec.herokuapp.com/companies/${shop}/orders/dashboard_data?startDate=${formattedStartDate}&endDate=${formattedEndDate}&getEligibleOrders=${eligibleOrdersFilter}`;

      const response = await fetchWithSessionToken(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProtectedOrders(data.orders.protectedOrders);
        setNonProtectedOrders(data.orders.nonProtectedOrders);
        setRevenueGenerated(data.orders.revenueGenerated.toFixed(2));
        setProtectedAOV(data.orders.protectedAOV.toFixed(2));
        setNonProtectedAOV(data.orders.nonProtectedAOV.toFixed(2));
        setUnReviewClaims(data.claims.notReviewedClaims);
        setInReviewClaims(data.claims.inReviewClaims);
        setDeniedClaims(data.claims.deniedClaims);
        setApprovedClaims(data.claims.approvedClaims);
        setDailyAttachmentRates(data.orders.dailyAttachmentRates);
        setClientPayout(data.orders.clientPayout);
        setTopAttachedProducts(data.orders.topAttachedProducts);
        setLowestAttachedProducts(data.orders.lowestAttachedProducts);

        const rawLabels = Object.keys(data.orders.dailyAttachmentRates);
        
        const sortedDates = rawLabels
          .map(dateStr => new Date(dateStr))
          .sort((a, b) => a - b);
        
        const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });
        const formattedLabels = sortedDates.map(date => dateFormatter.format(date));
        
        const sortedValues = sortedDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyAttachmentRates[dateStr];
        });
        
        setBarData({
          labels: formattedLabels,
          datasets: [
            {
              label: 'Attachment % - Last 7 days',
              data: sortedValues,
              backgroundColor: '#8661FF',
              borderColor: '#5E19BF',
              borderWidth: 1,
              borderRadius: 10,
              barThickness: 10,
            },
          ],
        });

        const rawLineLabels = Object.keys(data.orders.dailyRevenueRates);
        
        const sortedLineDates = rawLineLabels
          .map(dateStr => new Date(dateStr))
          .sort((a, b) => a - b);
        
        const formattedLineLabels = sortedLineDates.map(date => dateFormatter.format(date));
        
        const sortedLineValues = sortedLineDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyRevenueRates[dateStr];
        });

        const sortedAttachmentValues = sortedDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyAttachmentRates[dateStr];
        });

        setLineData({
          labels: formattedLineLabels,
          datasets: [
            {
              label: 'Revenue',
              data: sortedLineValues,
              fill: false,
              borderColor: '#007bff',
              yAxisID: 'y-revenue',
            },
            {
              label: 'Attachment Rate',
              data: sortedAttachmentValues,
              fill: false,
              borderColor: '#ff6384',
              yAxisID: 'y-attachment',
            },
          ],
        });
      }
    } catch (error) {
      console.error('Failed to fetch protected orders count:', error);
    }
  };

  const handlePreset = (preset) => {
    let newDateRange;
    switch (preset) {
      case 'today':
        newDateRange = getToday();
        break;
      case 'thisWeek':
        newDateRange = getThisWeek();
        break;
      case 'lastWeek':
        newDateRange = getLastWeek();
        break;
      case 'thisMonth':
        newDateRange = getThisMonth();
        break;
      case 'lastMonth':
        newDateRange = getLastMonth();
        break;
      case 'thisYear':
        newDateRange = getThisYear();
        break;
      default:
        newDateRange = getToday();
    }
    handleDateChange(newDateRange);
  };

  const handleSelect = (shopName) => {
    setShop(shopMapping[shopName]);
  };

  const getShopName = (shopUrl) => {
    return Object.keys(shopMapping).find(key => shopMapping[key] === shopUrl);
  };

  const totalEligibleOrders = protectedOrders + nonProtectedOrders;
  const totalEligibleClaims = inReviewClaims + approvedClaims + deniedClaims + unReviewClaims;

  const attachmentRate = totalEligibleOrders > 0 ? ((protectedOrders / totalEligibleOrders) * 100).toFixed(2) : 0;

  if (isLoggedIn === null || isLoggedIn === false) {
    return <LoadingIndicator />;
  } else {
    return (
      <div>
        <NavBarMenu shouldLoadChatWidget={false} />
        <div className="main-content content-padding">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle">Overview</h6>
                    <h1 className="header-title">Dashboard</h1>
                  </div>
                  <div className="col-auto">
                    <div>
                      <Flatpickr
                        className="btn btn-primary lift"
                        value={date}
                        onChange={handleDateChange}
                        options={{ mode: 'range', dateFormat: 'M d' }}
                      />
                      <div style={{ padding: '10px 0' }}> {/* Adjust padding as needed */}
                        <Button className="btn btn-primary lift" onClick={() => setIsOpen(true)}>
                          Filters
                        </Button>
                      </div>
                      <div>
                        <DropdownButton id="dropdown-basic-button" title={getShopName(shop)} onSelect={handleSelect}>
                          {Object.keys(shopMapping).map((shopName) => (
                            <Dropdown.Item eventKey={shopName} key={shopName}>{shopName}</Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                      <div style={{ padding: '10px 0' }}>
                        <Button className="btn btn-primary lift" onClick={() => backfillData(shop)}>
                          {isBackfilling ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Backfill'}
                        </Button>
                      </div>
                    </div>
                    <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg" centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-6">
                            <Flatpickr
                              className="form-control"
                              value={date}
                              onChange={handleDateChange}
                              options={{ mode: 'range', dateFormat: 'M d' }}
                            />
                          </div>
                          <div className="col-md-6">
                            <ButtonGroup vertical className="w-100">
                              <Button variant="outline-secondary" onClick={() => handlePreset('today')}>
                                Today
                              </Button>
                              <Button variant="outline-secondary" onClick={() => handlePreset('thisWeek')}>
                                This Week
                              </Button>
                              <Button variant="outline-secondary" onClick={() => handlePreset('lastWeek')}>
                                Last Week
                              </Button>
                              <Button variant="outline-secondary" onClick={() => handlePreset('thisMonth')}>
                                This Month
                              </Button>
                              <Button variant="outline-secondary" onClick={() => handlePreset('lastMonth')}>
                                Last Month
                              </Button>
                              <Button variant="outline-secondary" onClick={() => handlePreset('thisYear')}>
                                This Year
                              </Button>
                            </ButtonGroup>

                            {/* Additional Filters */}
                            <div className="mt-4">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="repeatCustomers"
                                  label="Repeat Customers"
                                  checked={repeatCustomersFilter}
                                  onChange={handleRepeatCustomerChange}
                                />
                                <Form.Check
                                  type="switch"
                                  id="eligibleOrders"
                                  label="Eligible Orders"
                                  checked={eligibleOrdersFilter}
                                  onChange={handleEligibleOrdersChange}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              {clientPayout > 0 && (
                <div className="col-12 col-lg-6 col-xl">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center gx-0">
                        <div className="col">
                          <h6 className="text-uppercase text-body-secondary mb-2">Client Payout</h6>
                          <span className="h2 mb-0">${clientPayout.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Revenue</h6>
                        <span className="h2 mb-0">${revenueGenerated}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Protected AOV</h6>
                        <span className="h2 mb-0">${protectedAOV}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Non Protected AOV</h6>
                        <span className="h2 mb-0">${nonProtectedAOV}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Protected #</h6>
                        <span className="h2 mb-0">{protectedOrders}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Non Protected #</h6>
                        <span className="h2 mb-0">{nonProtectedOrders}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Attached %</h6>
                        <span className="h2 mb-0">{attachmentRate}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">New Claims</h6>
                        <span className="h2 mb-0">{unReviewClaims}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-8">
                  <div className="card">
                    <div className="chart-container">
                    {barData && barData.datasets && barData.datasets.length > 0 ? (
                        <Bar data={barData} options={barOptions} />
                    ) : (
                        <p>No data available</p>
                    )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="card">
                      <div className="chart-container">
                        <Doughnut data={doughnutData} options={doughnutOptions} />
                      </div>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="chart-container">
                    {lineData && lineData.datasets && lineData.datasets.length > 0 ? (
                        <Line data={lineData} options={lineOptions} />
                    ) : (
                        <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="card">
                    <div className="chart-container">
                      <Bar data={topProductsData} options={barOptions} />
                    </div>
                  </div>
                </div>

                {/* Bar Chart for Lowest Attached Products */}
                <div className="col-12 col-lg-6">
                  <div className="card">
                    <div className="chart-container">
                      <Bar data={lowestProductsData} options={barOptionsLowest} />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}
