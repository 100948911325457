import React from 'react';
import { Container, Image, Card, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavBarMenu from './NavbarMenu';
import Footer from './Footer';

function PrivacyPolicy() {
  return (
    <>
      <NavBarMenu shouldLoadChatWidget={false} />
      <div style={{ paddingTop: '100px' }}>
        <Container className="py-5">
          <Row className="justify-content-center">
            <Image src="/brella_logo.png" className="mb-4" fluid style={{ width: '200px', height: 'auto' }} />
          </Row>
          <Card className="shadow">
            <Card.Body>
              <h1 className="text-center mb-4">Privacy Policy</h1>
              <p>This Privacy Policy ("Policy") applies to <a href="https://www.brella-ai-shop.com/">https://www.brella-ai-shop.com/</a>, and Brella.ai LLC ("Company") and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company include <a href="https://www.brella-ai-shop.com/">https://www.brella-ai-shop.com/</a>. The Company's website is an E-Commerce Services site. By using the Company website, you consent to the data practices described in this statement.</p>
              
              <h2>Collection of your Personal Information</h2>
              <p>In order to better provide you with products and services offered, the Company may collect personally identifiable information, such as your:</p>
              <ul>
                <li>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include:
                  <ol type="a">
                    <li>Registering for an account;</li>
                    <li>Entering a sweepstakes or contest sponsored by us or one of our partners;</li>
                    <li>Signing up for special offers from selected third parties;</li>
                    <li>Sending us an email message;</li>
                    <li>Submitting your credit card or other payment information when ordering and purchasing products and services.</li>
                  </ol>
                </li>
              </ul>
              <p>To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
              
              <h2>Sharing Information with Third Parties</h2>
              <p>The Company does not sell, rent, or lease its customer lists to third parties. The Company may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to the Company, and they are required to maintain the confidentiality of your information.</p>
              <p>The Company may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to:
                <ol>
                  <li>Conform to the edicts of the law or comply with legal process served on the Company or the site;</li>
                  <li>Protect and defend the rights or property of the Company; and/or</li>
                  <li>Act under exigent circumstances to protect the personal safety of users of the Company, or the public.</li>
                </ol>
              </p>
              
              <h2>Automatically Collected Information</h2>
              <p>The Company may automatically collect information about your computer hardware and software. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding the use of the Company's website.</p>
              
              <h2>Use of Cookies</h2>
              <p>The Company's website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
              
              {/* Content truncated for brevity */}
              
              <h2>Contact Information</h2>
              <p>The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:</p>
              <ul>
                <li>Direct any service providers to delete your personal information from their records.</li>
                <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, and provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>
                {/* Additional points truncated for brevity */}
              </ul>
              
              <p>This is a RocketLawyer.com document.</p>
              
              <address>
                Brella.ai LLC<br />
                American Fork, Utah 84003<br />
                Email Address: <a href="mailto:hayden@brella.ai">hayden@brella.ai</a><br />
                Phone Number: 5093080274
              </address>
              
              <p>Effective as of January 29, 2024</p>
              
            </Card.Body>
          </Card>
        </Container>
      </div>

    </>
  );
}

export default PrivacyPolicy;