import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Form, Button, Modal } from 'react-bootstrap';
import './ChatHistoryStyle.css';
import { fetchWithSessionToken } from "./session_http_helper";


const LiveChatbot = ({ shop }) => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chatWindowRef = useRef(null);
  const [showEndChatModal, setShowEndChatModal] = useState(false);
  const [endingSessionId, setEndingSessionId] = useState(null);
  const [ws, setWs] = useState(null);

  const fetchChatSession = async (sessionId) => {
    try {
      const response = await fetchWithSessionToken(
        `https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/session/${sessionId}?companyId=${shop}`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setMessages(data.messages);
    } catch (error) {
      console.error('Error fetching chat session messages:', error);
    }
  };

  const handleEndChat = async (sessionId) => {
    try {
      const response = await fetchWithSessionToken(
        `https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/${sessionId}/${shop}/endChat`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (ws && ws.readyState === WebSocket.OPEN) {
        const message = {
          role: "server",
          text: newMessage,
          chatState: "END_OF_CHAT",
          type: "text",
        };
        ws.send(JSON.stringify(message));
        console.log("Message sent via WebSocket:", message);
        ws.close();
      }
  
      // Remove the session from the list after successful response
      setSessions((prevSessions) =>
        prevSessions.filter((session) => session.sessionId !== sessionId)
      );
  
      // Clear the chat window and reset the selected session
      setSelectedSession(null);
      setMessages([]);
  
      // Close the modal
      setShowEndChatModal(false);
      setEndingSessionId(null);
    } catch (error) {
      console.error('Error ending chat:', error);
    }
  };  
  
  useEffect(() => {
    const fetchSessions = async (chatStarted = null) => {
      try {
        const response = await fetchWithSessionToken(
          `https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/company/${shop}?liveChatRequested=true`, 
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setSessions(data);
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
      }
    };
  
    fetchSessions();
  }, [shop]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSessionClick = (sessionId) => {
    setSelectedSession(sessionId);
    fetchChatSession(sessionId);
  };

  const connectToWebSocket = (sessionId) => {
    const webSocketUrl = `wss://brella-chatbot-64b531dcb02f.herokuapp.com/ws/chat?sessionId=${sessionId}`;
    const establishedwS = new WebSocket(webSocketUrl);
    setWs(establishedwS);
  
    establishedwS.onopen = () => {
      console.log("WebSocket connection established for session:", sessionId);
    };
  
    establishedwS.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Message from WebSocket (JSON):", data);
  
        if (data.role === 'user') {
          const newData = {
            text: data.responseContent,
            role: data.role,
            type: "text",
          }
          setMessages((prevMessages) => [...prevMessages, newData]);
        }
      } catch (error) {
        console.warn("Non-JSON WebSocket message received:", event.data);
  
        // Handle plain text messages
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: event.data,
            sender: "user",
            type: "text",
          },
        ]);
      }
    };
  
    establishedwS.onclose = () => {
      console.log("WebSocket connection closed for session:", sessionId);
      setWs(null);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: 'Chat session ended',
          sender: "server",
          type: "text",
        },
      ]);
    };
  
    establishedwS.onerror = (error) => {
      console.error("WebSocket error:", error);
      setWs(null);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: 'An error occured: ' + error,
          sender: "server",
          type: "text",
        },
      ]);
    };
  };  

  const handleStartChat = async (sessionId) => {
    try {
      const response = await fetchWithSessionToken(
        `https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/${shop}/${sessionId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const message = await response.text();
      console.log(message);
  
      // Update the specific session to mark it as started
      setSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.sessionId === sessionId
            ? { ...session, chatStarted: true }
            : session
        )
      );

      connectToWebSocket(sessionId);
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };  

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const message = {
        role: "server",
        text: newMessage,
        type: "text", // Optional
      };
  
      try {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(message));
          console.log("Message sent via WebSocket:", message);
  
          // Optimistically update the chat UI
          setMessages((prevMessages) => [...prevMessages, message]);
        } else {
          console.warn("WebSocket is not open. Falling back to HTTP request.");
          const response = await fetchWithSessionToken(
            `https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/session/${selectedSession}/message?companyId=${shop}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(message),
            }
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          // Update the chat UI with the new message
          setMessages((prevMessages) => [...prevMessages, message]);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setNewMessage(""); // Clear the input field
      }
    }
  };  
  
  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col xs={4} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
          <h3>Sessions</h3>
          <ListGroup>
            {sessions.map((session, index) => (
              <ListGroupItem
                as="div"
                key={session._id || `${session.sessionId}-${index}`}
                action
                onClick={() => handleSessionClick(session.sessionId)}
              >
                {session.sessionId}
                <Button
                  variant={session.chatStarted ? "secondary" : "success"}
                  size="sm"
                  className="ml-2"
                  disabled={session.chatStarted} // Disable if in progress
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!session.chatStarted) {
                      handleStartChat(session.sessionId);
                    }
                  }}
                >
                  {session.chatStarted ? "In Progress" : "Start Chat"}
                </Button>
                {session.chatStarted && (
                  <Button
                    variant="danger"
                    size="sm"
                    className="ml-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEndChatModal(true);
                      setEndingSessionId(session.sessionId);
                    }}
                  >
                    End Chat
                  </Button>
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col xs={8}>
          <h3>Chat Window</h3>
          <div className='chat-window' ref={chatWindowRef}>
            {selectedSession ? (
              messages.length > 0 ? (
                messages.map((message, index) => (
                  <div key={message._id || index} className={`message ${message.role}`}>
                    <div className="role">{message.role}</div>
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    ></div>
                  </div>
                ))
              ) : (
                <p>No messages found for this session.</p>
              )
            ) : (
              <p>Select a session to view messages.</p>
            )}
          </div>
          {selectedSession && (
            <Form className="mt-3" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" disabled={!newMessage.trim()}>Send</Button>
            </Form>
          )}
        </Col>
      </Row>

      <Modal
        show={showEndChatModal}
        onHide={() => {
          setShowEndChatModal(false);
          setEndingSessionId(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>End Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to end this chat session?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => handleEndChat(endingSessionId)}
          >
            Yes, End Chat
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEndChatModal(false);
              setEndingSessionId(null);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default LiveChatbot;
