import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {

  const [userLogin, setUserLogin] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState('BASIC');

  useEffect(() => {
    const userLoggedIn = localStorage.getItem('loggedIn') === 'true';
    const userPermission = localStorage.getItem('permissionLevel');
    setUserLogin(userLoggedIn);
    setPermissionLevel(userPermission || 'BASIC');
  }, []);

  return (
    <footer className="py-5" style={{ backgroundColor: "#000000", color: "light" }}>
      <Container>
        <Row>
          {/* Left Column: Logo and Legal Info */}
          <Col md={6}>
            <img src="Brella_White.png" alt="Brella" className="footer-logo mb-3" />
            <p className="footer-text">&copy; 2024 Brella.ai — All rights reserved.</p>
            <ul className="footer-nav list-unstyled">
              <li>
                <Link to="/PrivacyPolicy" className="footer-link">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/TermsService" className="footer-link">Terms and Conditions</Link>
              </li>
            </ul>
          </Col>

          {/* Right Column: Navigation Links */}
          <Col md={6} className="text-md-end">
            <ul className="footer-nav list-unstyled">
              <li>
                <Link to="/" className="footer-link">About</Link>
              </li>
              <li>
                <Link to="/" className="footer-link">Get Started</Link>
              </li>
              <li>
                <Link to={userLogin ? "/Logout" : "/Login"} className="footer-link">
                  {userLogin ? "Logout" : "Login"}
                </Link>
              </li>
              <li>
                <Link to="/ScheduleDemo" className="footer-link">Schedule Demo</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
