import React, { useEffect, useState } from 'react';
import NavBarMenu from './NavbarMenu';
import axios from 'axios';
import { Tabs, Tab, Container } from 'react-bootstrap';
import PartnerList from './PartnerList';
import CreatePartner from './CreatePartner';
import { fetchWithSessionToken } from './session_http_helper';
import WelcomeEmail from './WelcomeEmail';

const BASE_URL = 'https://brella-protect-514098c560ec.herokuapp.com/partners';

function PartnerSetup() {
  const [partners, setPartners] = useState([]);
  const [newPartner, setNewPartner] = useState({
    companyIds: [{ companyName: '', companyId: '', commission: '' }],
    stripeCustomerId: '',
    acceptedAgreement: false,
    address: '',
    email: ''
  });
  const [activeTab, setActiveTab] = useState('create');

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await fetchWithSessionToken(`${BASE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const fetchedPartners = data.map((partner) => ({
        ...partner,
        companyIds: partner.companyIds || [],
      }));
      setPartners(fetchedPartners);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewPartner({
      ...newPartner,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleCompanyNameChange = (event, index) => {
    const { value } = event.target;
    const companyIds = [...newPartner.companyIds];
    companyIds[index].companyName = value;
    setNewPartner({ ...newPartner, companyIds });
  };

  const handleCompanyCommissionChange = (event, index) => {
    const { value } = event.target;
    const companyIds = [...newPartner.companyIds];
    companyIds[index].commission = value;
    setNewPartner({ ...newPartner, companyIds });
  };

  const handleCompanyIdChange = (event, index) => {
    const { value } = event.target;
    const companyIds = [...newPartner.companyIds];
    companyIds[index].companyId = value;
    setNewPartner({ ...newPartner, companyIds });
  };

  const handleAddCompanyRow = () => {
    setNewPartner({
      ...newPartner,
      companyIds: [...newPartner.companyIds, { companyName: '', companyId: '', commission: ''  }]
    });
  };

  const handleDeleteCompanyRow = (index) => {
    const companyIds = [...newPartner.companyIds];
    companyIds.splice(index, 1);
    setNewPartner({ ...newPartner, companyIds });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetchWithSessionToken(`${BASE_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPartner),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      fetchPartners();
      setNewPartner({
        companyIds: [{ companyName: '', companyId: '', commission: '' }],
        stripeCustomerId: '',
        acceptedAgreement: false,
        address: '',
        email: '',
      });
    } catch (error) {
      console.error('Error creating partner:', error);
    }
  };
  

  const welcomeMessageHtml = `
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: #f8f8f8;
      }
      .partnersetup_container {
        background-color: white;
        margin: 0 auto;
        padding: 20px;
        max-width: 600px;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
      }
      .partnersetup_header {
        margin-bottom: 20px;
      }
      .partnersetup_header img {
        width: 150px;
      }
      .partnersetup_title {
        font-size: 24px;
        color: #333;
      }
      .partnersetup_subtitle {
        font-size: 18px;
        color: #555;
        margin-bottom: 20px;
      }
      .partnersetup_highlight {
        font-weight: bold;
        color: #5a67d8;
      }
      .partnersetup_button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        color: white;
        background-color: #5a67d8;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 20px;
      }
      .partnersetup_footer {
        border-top: 1px solid #eaeaea;
        padding-top: 20px;
        margin-top: 20px;
        color: #999;
        font-size: 14px;
      }
      .partnersetup_footer img {
        width: 70px;
      }
      .partnersetup_image-placeholder {
        width: 100%;
        height: 200px;
        background-color: #eaeaea;
        border: 2px dashed #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
      }
      .partnersetup_image-placeholder img {
        width: 50px;
      }
    </style>
  </head>
  <body>
    <div class="partnersetup_container">
      <div class="partnersetup_header">
        <img src="https://d1f6rj4sne1926.cloudfront.net/brella_logo.png" alt="Brella Logo" />
      </div>
      <div class="partnersetup_title">
        Welcome to the Brella Squad!
      </div>
      <div class="partnersetup_subtitle">
        Congratulations, you've just joined the <span class="partnersetup_highlight">coolest</span> team in e-commerce! As a Referral Partner, you’re now officially part of the Brella family. Prepare yourself for an adventure filled with AI magic, game-changing tools, and a touch of our signature humor. Let's turn rainy days into revenue together!
      </div>
      <a href="https://brella.ai" class="partnersetup_button">Get Started</a>
      <div class="partnersetup_footer">
        <a href="https://instagram.com">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 2H16.25C20.04 2 23 4.96 23 8.75V16.25C23 20.04 20.04 23 16.25 23H7.75C3.96 23 1 20.04 1 16.25V8.75C1 4.96 3.96 2 7.75 2ZM0 8.75V16.25C0 20.75 3.25 24 7.75 24H16.25C20.75 24 24 20.75 24 16.25V8.75C24 4.25 20.75 1 16.25 1H7.75C3.25 1 0 4.25 0 8.75ZM12 6.5C9.11 6.5 6.5 9.11 6.5 12C6.5 14.89 9.11 17.5 12 17.5C14.89 17.5 17.5 14.89 17.5 12C17.5 9.11 14.89 6.5 12 6.5ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM18 5.5C18.55 5.5 19 5.05 19 4.5C19 3.95 18.55 3.5 18 3.5C17.45 3.5 17 3.95 17 4.5C17 5.05 17.45 5.5 18 5.5Z" fill="#C13584"/>
            </svg>
        </a>
        <br />        <br />        <br />
        <img src="https://d1f6rj4sne1926.cloudfront.net/brella_logo.png" alt="Brella Logo" />
      </div>
    </div>
  </body>
  </html>  
  `;

  const renderContent = () => {
    switch (activeTab) {
      case 'partners':
        return <PartnerList partners={partners} />;
      case 'create':
        return <CreatePartner
                  newPartner={newPartner}
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                  handleCompanyNameChange={handleCompanyNameChange}
                  handleCompanyIdChange={handleCompanyIdChange}
                  handleCompanyCommissionChange={handleCompanyCommissionChange}
                  handleAddCompanyRow={handleAddCompanyRow}
                  handleDeleteCompanyRow={handleDeleteCompanyRow}
                />;
      case 'welcome':
        return <WelcomeEmail welcomeMessageHtml={welcomeMessageHtml} />;
      default:
        return <CreatePartner
                  newPartner={newPartner}
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                  handleCompanyNameChange={handleCompanyNameChange}
                  handleCompanyIdChange={handleCompanyIdChange}
                  handleCompanyCommissionChange={handleCompanyCommissionChange}
                  handleAddCompanyRow={handleAddCompanyRow}
                  handleDeleteCompanyRow={handleDeleteCompanyRow}
                />;
    }
  };

  return (
    <>
      <NavBarMenu shouldLoadChatWidget={false} />
      <div className="main-content" style={{ paddingTop: '100px' }}>
        <div className="header">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="header-title">
                    Setup Partner Info
                  </h1>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col">
                    <ul className="nav nav-tabs nav-overflow header-tabs">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${activeTab === 'create' ? 'active' : ''}`}
                          onClick={() => setActiveTab('create')}
                        >
                          Create New Partner
                        </button>
                      </li>
                      <li className="nav-item active">
                        <button
                          className={`nav-link ${activeTab === 'partners' ? 'active' : ''}`}
                          onClick={() => setActiveTab('partners')}
                        >
                          Partners
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${activeTab === 'welcome' ? 'active' : ''}`}
                          onClick={() => setActiveTab('welcome')}
                        >
                          View Welcome Email
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderContent()}
        </div>
      </div>
    </>
  );
}

export default PartnerSetup;
