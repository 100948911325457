import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = (requiredPermissionLevel) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSessionToken = () => {
      const loggedIn = localStorage.getItem('loggedIn');
      const permissionLevel = localStorage.getItem('permissionLevel');

      if (!loggedIn) {
        navigate('/Home');
        setIsAuthorized(false);
        return;
      }

      if (permissionLevel !== requiredPermissionLevel && permissionLevel !== 'SUPER_ADMIN') {
        navigate('/Home');
        setIsAuthorized(false);
        return;
      }

      setIsAuthorized(true);
    };

    checkSessionToken();
  }, [navigate, requiredPermissionLevel]);

  return isAuthorized;
};

export default useAuth;
