import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import dataStyle from './Data.module.css'
import NavBar from './NavbarMenu';
import { fetchWithSessionToken } from './session_http_helper';

export default function DataTraining() {
    const [companyId, setCompanyId] = useState('brellatest');
    const [messages, setMessages] = useState([]);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editContent, setEditContent] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [statusFilter, setStatusFilter] = useState(''); // New state for status filter
    const [jobId, setJobId] = useState(null);
    const [jobStatus, setJobStatus] = useState('');
    
    const checkJobStatus = async () => {
        if (!jobId) {
            alert("No job ID found. Please upload first.");
            return;
        }
        try {
            const response = await fetch(`https://brella-chat-training-c89a4369c2fc.herokuapp.com/check-status/${jobId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const jsonResponse = await response.text();
                console.log('Job status retrieved:', jsonResponse);
                setJobStatus(`Job status: ${jsonResponse}`);
            } else {
                console.warn('Failed to retrieve job status:', response.status, await response.text());
                setJobStatus('Failed to retrieve job status.');
            }
        } catch (error) {
            console.error('Error fetching job status:', error);
            setJobStatus('Error fetching job status.');
        }
    };

    const handleEdit = (index) => {
        const editableMessages = messages[index].messages.filter(msg => msg.role !== 'system');
        const editableContent = { ...messages[index], messages: editableMessages };
        delete editableContent.id; // Remove the id field for editing
    
        setEditingIndex(index);
        setEditContent(JSON.stringify(editableContent, null, 2)); // Pretty print JSON
    };

    const handleEditChange = (event) => {
        setEditContent(event.target.value);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedMessages([...messages]);
        } else {
            setSelectedMessages([]);
        }
    };

    function calculateTrainingPrice(baseCostPer1kTokens, totalTokens, numberOfEpochs) {
        // Convert total tokens to thousands
        const totalTokensInThousands = totalTokens / 1000;
    
        // Calculate the total cost
        const totalCost = baseCostPer1kTokens * totalTokensInThousands * numberOfEpochs;
    
        return totalCost;
    }

    const Message = ({ role, content }) => {
        let className = '';
        let roleName = '';
        switch (role) {
          case 'system':
            // Don't return any JSX if it's a system message.
            return null;
          case 'user':
            className = `${dataStyle.message} ${dataStyle.userMessage}`;
            roleName = 'User';
            break;
          case 'assistant':
            className = `${dataStyle.message} ${dataStyle.assistantMessage}`;
            roleName = 'Assistant';
            break;
          default:
            className = `${dataStyle.message}`;
            roleName = 'Unknown';
        }
      
        return (
          <div className={className}>
            <span className={dataStyle.roleLabel}>{roleName}:</span> {content}
          </div>
        );
      };
          

    function estimateTrainingCost(data, costPerMillionTokens) {
        let estimatedTokens = 0;
        data.forEach(item => {
            // Join all messages into a single string and count the characters
            const messageString = item.messages.join(' ');
            estimatedTokens += messageString.length;
        });
    
        const baseCostPer1kTokens = 0.008; // $0.008 per 1k tokens
        const numberOfEpochs = 3; // Assuming 1 epoch

        const trainingPrice = calculateTrainingPrice(baseCostPer1kTokens, estimatedTokens, numberOfEpochs);
    
        return {
            estimatedCost: trainingPrice
        };
    }    

    // Fetch data function
    const fetchData = async (status = '') => {
        try {
            const url = `https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/message?companyId=${companyId}${status ? `&status=${status}` : ''}`;
            const response = await fetchWithSessionToken(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            if (data) {
                setMessages(data.messageGroups);
                setSystemPrompt(data.systemPrompt);
            }

        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        const trainingCost = 8.00; // cost per 1 million tokens for training
        const estimate = estimateTrainingCost(selectedMessages, trainingCost);
        setEstimatedCost(estimate.estimatedCost);
    }, [selectedMessages]);

    // Function to handle selection
    const handleSelect = (messagesObj, isChecked) => {
        setSelectedMessages(prevSelectedMessages => {
            if (isChecked) {
                return [...prevSelectedMessages, messagesObj];
            } else {
                // Remove the messages object from the selected messages
                return prevSelectedMessages.filter(selected => selected !== messagesObj);
            }
        });
    };

    // Function to handle upload
    const handleUpload = async () => {
        try {
            if (selectedMessages.length > 0) {
                const jsonDataArray = selectedMessages.map(msgObj => {
                    const updatedMessages = [...msgObj.messages, { role: 'system', content: systemPrompt }];
                    return JSON.stringify({ "messages": updatedMessages });
                });
                
                const jsonlData = jsonDataArray.join('\n');

                const response = await fetch('https://brella-chat-training-c89a4369c2fc.herokuapp.com/create-model', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: jsonlData
                });
        
                if (response.ok) {
                    const jsonResponse = await response.json();
                    console.log('Upload successful:', jsonResponse);
    
                    // Extract the job_id from the response and set it to the jobId state
                    if (jsonResponse.job_id) {
                        setJobId(jsonResponse.job_id);
                        console.log(`Job ID: ${jsonResponse.job_id}`);
                    }
                } else {
                    // Handle error response
                    console.warn('Failed to upload data:', response.status, await response.text());
                }
            } else {
                console.warn('No message group selected for upload.');
            }
        } catch (error) {
            console.error('Failed to upload data:', error);
        }
    };
    

    const handleDelete = async () => {
        try {
            if (selectedMessages.length > 0) {
                // Extract just the IDs from selectedMessages
                const idsToDelete = selectedMessages.map(messageGroup => messageGroup.id);
    
                // Ensure your backend is set up to parse the JSON body as an array of IDs
                const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/message?companyId=${companyId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(idsToDelete) // Send JSON array of IDs
                });
    
                if (response.ok) {
                    const jsonResponse = await response.json();
                    console.log('Deletion successful:', jsonResponse);
    
                    const remainingMessages = messages.filter(messageGroup => !idsToDelete.includes(messageGroup.id));
                    setMessages(remainingMessages);
                    setSelectedMessages([]); // Clear selection after deletion
                } else {
                    console.warn('Failed to delete data:', response.status, await response.text());
                }
            } else {
                console.warn('No message group selected for deletion.');
            }
        } catch (error) {
            console.error('Failed to delete data:', error);
        }
    };

    const handleAddMessage = () => {
        const newMessageBlock = [
            {
                role: 'user',
                content: ''
            }, 
            {
                role: 'assistant',
                content: ''
            }
    ];
    
        setMessages(prevMessages => [...prevMessages, { messages: newMessageBlock }]);
    };
    
    const handleSaveEdit = async () => {
        try {
            if (editingIndex !== null) {
                const editedContent = JSON.parse(editContent);
                const originalMessageGroup = messages[editingIndex];
    
                const updatedMessageGroup = {
                    ...originalMessageGroup,
                    messages: [...editedContent.messages, ...originalMessageGroup.messages.filter(msg => msg.role === 'system')]
                };
    
                const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/message_id/${originalMessageGroup.id}?companyId=${companyId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedMessageGroup)
                });
    
                if (response.ok) {
                    console.log('Edit saved successfully.');
                } else {
                    console.warn('Failed to save edit:', response.status, await response.text());
                }
    
                // Update the state if necessary
                const updatedMessages = [...messages];
                updatedMessages[editingIndex] = updatedMessageGroup;
                setMessages(updatedMessages);
                setEditingIndex(null); // Exit editing mode
            } else {
                console.warn('No message group is currently being edited.');
            }
        } catch (error) {
            console.error('Failed to save edit:', error);
        }
    };     
    
    const handleSearch = () => {
        fetchData(statusFilter);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <NavBar />
            <div>
                <div className={dataStyle.messages_list}>
                    <Row>
                        {/* First column for buttons */}
                        <Col xs={12} md={6}>
                            <h2>Select and Upload Data</h2>
                            <Button variant="secondary" onClick={checkJobStatus}>
                                Check Job Status
                            </Button>
                            <Button onClick={handleUpload}>Upload Selected Message</Button>
                            <Button onClick={handleDelete}>Delete Selected Message</Button>
                            <p>Estimated Training Cost: ${estimatedCost.toFixed(2)}</p>
                            <Form.Check 
                                type="checkbox"
                                label="Select All"
                                checked={selectedMessages.length === messages.length}
                                onChange={handleSelectAll}
                            />
                        </Col>

                        {/* Second column for filters dropdown */}
                        <Col xs={12} md={4}>
                            <h2>Add Filters</h2>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Select Filter</Form.Label>
                                <Form.Control as="select" onChange={(e) => fetchData(e.target.value)}>
                                    <option value="">All Messages</option>
                                    <option value="positive">Positive Feedback</option>
                                    <option value="negative">Negative Feedback</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    {messages.map((messageObj, index) => (
                        <div key={index} className={dataStyle.message_item}>
                            <input
                                type="checkbox"
                                checked={selectedMessages.includes(messageObj)}
                                onChange={(e) => handleSelect(messageObj, e.target.checked)}
                            />
                            {editingIndex === index ? (
                                <textarea
                                    className={dataStyle.fullWidthTextArea}
                                    value={editContent}
                                    onChange={handleEditChange}
                                />
                            ) : (
                                <span onClick={() => handleEdit(index)}>
                                {editingIndex === index
                                    ? (
                                        <textarea
                                            className={dataStyle.fullWidthTextArea}
                                            value={editContent}
                                            onChange={handleEditChange}
                                        />
                                    )
                                    : (
                                        // Display mode: show styled messages
                                        messageObj.messages.map((msg, msgIndex) => (
                                        <Message key={msgIndex} role={msg.role} content={msg.content} />
                                        ))
                                    )
                                }
                                </span>
                            )}
                            {editingIndex === index && (
                                <button onClick={() => handleSaveEdit(index)}>Save</button>
                            )}
                        </div>
                    ))}
                    <Button onClick={handleAddMessage}>Add Message</Button>
                </div>
            </div>
        </>
    );
}
