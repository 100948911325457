import React from 'react';
import NavBarMenu from './NavbarMenu';
import { Container, Image, Row, Col, Button, Modal } from 'react-bootstrap';

const GetStarted = () => {

    return (
        <>
            <NavBarMenu shouldLoadChatWidget={true} />
            <Container fluid style={{
                background: "linear-gradient(to right, #b0a6ff, #dff7ff)",
                paddingTop: '200px',
                paddingBottom: '200px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8}>
                        <h1>Customer Satisfaction: Our Priority</h1>
                        <h3>Exceptional Experiences, Every Time</h3>
                        <p>At Brella.ai, customer satisfaction is at the heart of everything we do. 
                            We are committed to providing exceptional experiences for every store owner and their customers. 
                            With Claimsworth's impeccable service and our innovative solutions, we ensure every interaction is seamless and satisfactory.</p>
                        {/* <Container>
                            <Row className="justify-content-center pt-4">
                                <Col xs={12} md={6} lg={4} className="d-grid gap-2">
                                    <Button variant="outline-primary" size="lg" className="fw-bold">View Offerings</Button>
                                </Col>
                            </Row>
                        </Container> */}
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{
                paddingTop: '100px',
                paddingBottom: '100px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '300px' }}>
                                <Image src="https://d1f6rj4sne1926.cloudfront.net/package_being_protected.webp" alt="Image" fluid />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={8}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8}>
                                <h2>Brella.ai: Your Shipping Magician</h2>
                                <p>Shipping troubles got you feeling like you need to pull a rabbit out of a hat? Ta-da! Meet Brella.ai, 
                            your very own shipping magician! We put YOU in control with our merchant-driven protection, 
                            making those clumsy third-party providers disappear.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{
                paddingTop: '100px',
                paddingBottom: '100px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8} sm={12} xs={12} className="order-lg-1 order-md-2">
                        <Row className="justify-content-center align-items-center">
                        <Col xs={12} md={6} lg={8}>
                            <h2>Engage With Customers</h2>
                            <p>
                                Our widget integrates so smoothly, it's like a perfect magic trick. Just plug it in and—shazam!—instant peace of mind. 
                                It's like having a magic wand that waves away all your shipping woes—just remember, you’re the one holding the wand.
                            </p>
                            <p>
                                Transform customer complaints into applause-worthy reviews. With Brella.ai, you're not just protecting shipments; you're performing a show-stopping act of business excellence. 
                                Step into the spotlight of shipping protection with Brella.ai. We’ve got you covered. Literally.
                            </p>
                        </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={12} className="order-lg-2 order-md-1">
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '300px' }} className="justify-content-center align-items-center">
                                <Image src="https://d1f6rj4sne1926.cloudfront.net/package_being_protected.webp" alt="Image" fluid />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </>
    );
};

export default GetStarted;
