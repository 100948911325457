export async function fetchWithSessionToken(url, options = {}) {
    const token = localStorage.getItem('sessionToken');

    if (!token) {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('username');
        localStorage.removeItem('permissionLevel');
        window.location.href = '/Login'; // Redirect to the login page
        return Promise.reject('Session token not found');
    }

    if (!options.headers) {
        options.headers = {};
    }
    options.headers['Authorization'] = `Bearer ${token}`;

    try {
        const response = await fetch(url, options);

        // Handle 401 Unauthorized responses
        if (response.status === 403 || response.status === 401) {
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('sessionToken');
            localStorage.removeItem('username');
            localStorage.removeItem('permissionLevel');
            window.location.href = '/Login';
            return Promise.reject('Unauthorized');
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}