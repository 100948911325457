import React, { useEffect, useState, Suspense } from 'react';
import { getToday, getThisWeek, getLastWeek, getThisMonth, getLastMonth, getThisYear } from './dateUtils';
import useAuth from './use_auth';
import LoadingIndicator from './loading_dots';
import NavBarMenu from './NavbarMenu';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup, Button, Form, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { fetchWithSessionToken } from "./session_http_helper";
import layoutStyles from "./assets/css/Layout.css";
import 'react-datepicker/dist/react-datepicker.css';
import 'chart.js/auto';

export const links = () => {
  return [
    { rel: "stylesheet", href: layoutStyles }
  ];
};

export default function PartnerDashboard() {
  const [protectedOrders, setProtectedOrders] = useState(0);
  const [nonProtectedOrders, setNonProtectedOrders] = useState(0);
  const [revenueGenerated, setRevenueGenerated] = useState(0);
  const [clientPayout, setClientPayout] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useAuth('PARTNER');

  const [shop, setShop] = useState('all');

  const [shopMapping, setShopMapping] = useState({ All: 'all' });

  const [date, setDate] = useState(() => {
    let storedDates;
    try { 
      storedDates = localStorage.getItem('dashboardDateRange');
    } catch (error) {}
    if (storedDates) {
      try {
        const parsedDates = JSON.parse(storedDates);
        if (Array.isArray(parsedDates) && 
            parsedDates.length === 2 && 
            parsedDates.every(dateStr => !isNaN(Date.parse(dateStr)))) {
          return parsedDates.map(dateStr => new Date(dateStr));
        }
      } catch (error) {
        console.error("Error while parsing stored date:", error);
      }
    }
    const today = new Date();
    return [today, today];
  });

  const handleOutsideClick = (event) => {};

  const handleDateChange = (selectedDates) => {
    try {
      if (Array.isArray(selectedDates) && selectedDates.length === 2 && selectedDates.every(date => date instanceof Date && !isNaN(date.valueOf()))) {
        setDate(selectedDates);
      } else {
        console.error('Invalid date range selected:', selectedDates);
      }
    } catch (error) {
      console.error('Error handling date change:', error);
    }
  };  

  useEffect(() => {
    const loadPartnerShops = async () => {
      const partnerId = localStorage.getItem('partnerId');
      if (!partnerId) {
        console.error("No partnerId found in localStorage");
        return;
      }

      try {
        const response = await fetchWithSessionToken(
          `https://brella-protect-514098c560ec.herokuapp.com/partners/account_info?partnerId=${partnerId}`
        );

        if (response.ok) {
          const data = await response.json();
          const updatedShopMapping = { All: 'all' };
  
          // Assuming data contains a list of CompanyPartner objects in data.companies
          data.companies.forEach((company) => {
            updatedShopMapping[company.companyName] = company.companyId;
          });
  
          setShopMapping(updatedShopMapping);
        } else {
          console.error("Failed to fetch partner shops");
        }
      } catch (error) {
        console.error("Error fetching partner shops:", error);
      }
    };

    loadPartnerShops();
  }, []);

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dateRange', JSON.stringify(date));
    } catch (error) {
      console.error('Failed to update session storage:', error);
    }
  }, [date]);

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dashboardDateRange', dateRangeString);
    } catch (error) {
      console.error('Error saving date range to localStorage:', error);
    }
  }, [date]);

  useEffect(() => {
    try {
      const dateRangeString = JSON.stringify(date.map(date => date.toISOString()));
      localStorage.setItem('dashboardDateRange', dateRangeString);

      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    } catch (error) {
      console.error('Error in main useEffect:', error);
    }
  }, [date, isOpen]);

  useEffect(() => {
    if (shop) {
      fetchOrders(date[0], date[1]);
    }
  }, [shop, date]);

  const fetchOrders = async (startDate, endDate) => {
    try {
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      const partnerId = localStorage.getItem('partnerId');
      const url = `https://brella-protect-514098c560ec.herokuapp.com/companies/${shop}/orders/partner/dashboard_data?partnerId=${partnerId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;

      const response = await fetchWithSessionToken(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProtectedOrders(data.orders.protectedOrders);
        setNonProtectedOrders(data.orders.nonProtectedOrders);
        setRevenueGenerated(data.orders.revenueGenerated.toFixed(2));
        setClientPayout(data.orders.clientPayout);
        setDailyAttachmentRates(data.orders.dailyAttachmentRates);

        const rawLabels = Object.keys(data.orders.dailyAttachmentRates);
        
        const sortedDates = rawLabels
          .map(dateStr => new Date(dateStr))
          .sort((a, b) => a - b);
        
        const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });
        const formattedLabels = sortedDates.map(date => dateFormatter.format(date));
        
        const sortedValues = sortedDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyAttachmentRates[dateStr];
        });
        
        setBarData({
          labels: formattedLabels,
          datasets: [
            {
              label: 'Attachment % - Last 7 days',
              data: sortedValues,
              backgroundColor: '#8661FF',
              borderColor: '#5E19BF',
              borderWidth: 1,
              borderRadius: 10,
              barThickness: 10,
            },
          ],
        });

        const rawLineLabels = Object.keys(data.orders.dailyRevenueRates);
        
        const sortedLineDates = rawLineLabels
          .map(dateStr => new Date(dateStr))
          .sort((a, b) => a - b);
        
        const formattedLineLabels = sortedLineDates.map(date => dateFormatter.format(date));
        
        const sortedLineValues = sortedLineDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyRevenueRates[dateStr];
        });

        const sortedAttachmentValues = sortedDates.map(date => {
          const dateStr = date.toISOString().split('T')[0];
          return data.orders.dailyAttachmentRates[dateStr];
        });

        setLineData({
          labels: formattedLineLabels,
          datasets: [
            {
              label: 'Revenue',
              data: sortedLineValues,
              fill: false,
              borderColor: '#007bff',
              yAxisID: 'y-revenue',
            },
            {
              label: 'Attachment Rate',
              data: sortedAttachmentValues,
              fill: false,
              borderColor: '#ff6384',
              yAxisID: 'y-attachment',
            },
          ],
        });
      }
    } catch (error) {
      console.error('Failed to fetch protected orders count:', error);
    }
  };

  const handlePreset = (preset) => {
    let newDateRange;
    switch (preset) {
      case 'today':
        newDateRange = getToday();
        break;
      case 'thisWeek':
        newDateRange = getThisWeek();
        break;
      case 'lastWeek':
        newDateRange = getLastWeek();
        break;
      case 'thisMonth':
        newDateRange = getThisMonth();
        break;
      case 'lastMonth':
        newDateRange = getLastMonth();
        break;
      case 'thisYear':
        newDateRange = getThisYear();
        break;
      default:
        newDateRange = getToday();
    }
    handleDateChange(newDateRange);
  };

  const handleSelect = (shopName) => {
    setShop(shopMapping[shopName]);
  };

  const getShopName = (shopUrl) => {
    return Object.keys(shopMapping).find(key => shopMapping[key] === shopUrl);
  };

  const totalEligibleOrders = protectedOrders + nonProtectedOrders;

  const attachmentRate = totalEligibleOrders > 0 ? ((protectedOrders / totalEligibleOrders) * 100).toFixed(2) : 0;

  if (isLoggedIn === null || isLoggedIn === false) {
    return <LoadingIndicator />;
  } else {
    return (
      <div>
        <NavBarMenu shouldLoadChatWidget={false} />
        <div className="main-content" style={{paddingTop: '100px'}}>
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle">Overview</h6>
                    <h1 className="header-title">Dashboard</h1>
                  </div>
                  <div className="col-auto">
                    <div>
                      <Flatpickr
                        className="btn btn-primary lift"
                        value={date}
                        onChange={handleDateChange}
                        options={{ mode: 'range', dateFormat: 'M d' }}
                      />
                      <div style={{ padding: '10px 0' }}> {/* Adjust padding as needed */}
                        <Button className="btn btn-primary lift" onClick={() => setIsOpen(true)}>
                          Quick Filter Options
                        </Button>
                      </div>
                      <div>
                        <DropdownButton id="dropdown-basic-button" title={getShopName(shop)} onSelect={handleSelect}>
                          {Object.keys(shopMapping).map((shopName) => (
                            <Dropdown.Item eventKey={shopName} key={shopName}>{shopName}</Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                    <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg" centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Quick filters</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex">
                          <ButtonGroup vertical>
                            <Button variant="outline-secondary" onClick={() => handlePreset('today')}>
                              Today
                            </Button>
                            <Button variant="outline-secondary" onClick={() => handlePreset('thisWeek')}>
                              This Week
                            </Button>
                            <Button variant="outline-secondary" onClick={() => handlePreset('lastWeek')}>
                              Last Week
                            </Button>
                            <Button variant="outline-secondary" onClick={() => handlePreset('thisMonth')}>
                              This Month
                            </Button>
                            <Button variant="outline-secondary" onClick={() => handlePreset('lastMonth')}>
                              Last Month
                            </Button>
                            <Button variant="outline-secondary" onClick={() => handlePreset('thisYear')}>
                              This Year
                            </Button>
                          </ButtonGroup>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
            {clientPayout > 0 && (
                <div className="col-12 col-lg-6 col-xl">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center gx-0">
                        <div className="col">
                          <h6 className="text-uppercase text-body-secondary mb-2">Payout</h6>
                          <span className="h2 mb-0">${clientPayout.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Revenue</h6>
                        <span className="h2 mb-0">${revenueGenerated}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Protected #</h6>
                        <span className="h2 mb-0">{protectedOrders}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Non Protected #</h6>
                        <span className="h2 mb-0">{nonProtectedOrders}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center gx-0">
                      <div className="col">
                        <h6 className="text-uppercase text-body-secondary mb-2">Attached %</h6>
                        <span className="h2 mb-0">{attachmentRate}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
