import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Unset the local storage variable here
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('permissionLevel');
        localStorage.removeItem('username');
        localStorage.removeItem('sessionToken');
        navigate('/Home');
    }, []);

    return (
        <div>
        </div>
    );
};

export default Logout;
