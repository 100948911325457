import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Features from './Features';
import Home from './HomePage/Home';
import DataTraining from './DataTraining';
import Login from './Login';
import OptInConsent from './OptInConsent';
import ChatBot from './ChatBot';
import Protection from './Protection';
import CreateAccount from './CreateAccount';
import ForgotPassword from './ForgotPassword';
import TermsService from './TermsService';
import PrivacyPolicy from './PrivacyPolicy';
import AdminDashboard from './Dashboards/AdminDashboard';
import PartnerDashboard from './PartnerDashboard';
import PartnerList from './PartnerList';
import PartnerSetup from './PartnerSetup';
import CreatePartner from './CreatePartner';
import ScheduleDemo from './ScheduleDemo';
import GetStarted from './GetStarted';
import ScrollToTop from './ScrollToTop';
import FAQ from './FAQ';
import Logout from './Logout';
import Footer from './Footer';
import { AuthProvider } from './AuthContext';
import CustomizeChatbot from './CustomizeChatbot';
import libsBundleStyles from "./assets/css/libs.bundle.css";
import themeBundleStyles from "./assets/css/theme.bundle.css";
import stylesUrl from "./assets/css/Layout.css";

export const links = () => {
  return [
    { rel: "stylesheet", href: libsBundleStyles },
    { rel: "stylesheet", href: themeBundleStyles },
    { rel: "stylesheet", href: stylesUrl }
  ];
};

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/CustomizeChatbot" element={<CustomizeChatbot />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ChatBot" element={<ChatBot />} />
        <Route path="/OptInConsent" element={<OptInConsent />} />
        <Route path="/GetStarted" element={<GetStarted />} />
        <Route path="/Protection" element={<Protection />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/TermsService" element={<TermsService />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/AdminDashboard" element={<AdminDashboard />} />
        <Route path="/PartnerDashboard" element={<PartnerDashboard />} />
        <Route path="/PartnerList" element={<PartnerList />} />
        <Route path="/PartnerSetup" element={<PartnerSetup />} />
        <Route path="/CreatePartner" element={<CreatePartner />} />
        <Route path="/ScheduleDemo" element={<ScheduleDemo />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Logout" element={<Logout />} />
      </Routes>
      <Footer />
    </AuthProvider>
  );
}

export default App;
