import React, { useState } from 'react';
import NavBar from './NavbarMenu';
import { Container } from 'react-bootstrap';

import stylesUrl from "./assets/css/Layout.css";

export const links = () => {
  return [
    { rel: "stylesheet", href: stylesUrl }
  ];
};

const OptInConsent = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>Thank You!</h1>
        <p style={styles.message}>
          Your consent has been recorded. You will now receive SMS notifications.
        </p>
      </div>
    );
  }

  return (
    <>
    <NavBar shouldLoadChatWidget={false} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Container>
        <h1 style={styles.title}>Opt-In for SMS Notifications</h1>
        <p style={styles.message}>
            By submitting this form, you consent to receive SMS notifications from us.
        </p>
        <form onSubmit={handleSubmit}>
            <label style={styles.label}>
            Full Name:
            <input type="text" name="name" required style={styles.input} />
            </label>
            <label style={styles.label}>
            Phone Number:
            <input
                type="tel"
                name="phone"
                pattern="^\+?[1-9]\d{1,14}$"
                required
                style={styles.input}
                placeholder="+1234567890"
            />
            </label>
            <button type="submit" style={styles.button}>
            Submit Consent
            </button>
        </form>
        </Container>
      </div>
    </>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "400px",
    margin: "50px auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    textAlign: "center",
  },
  title: {
    color: "#333333",
    marginBottom: "10px",
  },
  message: {
    color: "#555555",
    marginBottom: "20px",
  },
  label: {
    display: "block",
    textAlign: "left",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginTop: "5px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #cccccc",
    fontSize: "14px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
};

export default OptInConsent;
