import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the context
export const AuthContext = createContext();

// Create the provider
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState('BASIC');

  const navigate = useNavigate();

  // Define the idle timeout duration (1 minute)
  const IDLE_TIMEOUT = 60 * 60 * 1000; // 60 minutes in milliseconds
  let idleTimer = null;

  // Function to handle logout
  const logout = useCallback(() => {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('permissionLevel');
    localStorage.removeItem('username');
    localStorage.removeItem('sessionToken');
    setIsLoggedIn(false);
    setPermissionLevel('BASIC');
    alert('Session expired. Please log in again.');
    navigate('/Login');
  }, []);

  // Function to handle activity and reset the timer
  const resetIdleTimer = useCallback(() => {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    if (isLoggedIn) {
      idleTimer = setTimeout(() => {
        console.log('User is idle. Logging out...');
        logout();
      }, IDLE_TIMEOUT);
    }
  }, [isLoggedIn, logout]);

  // Track user activity
  useEffect(() => {
    if (isLoggedIn) {
      const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

      // Attach event listeners
      events.forEach(event => window.addEventListener(event, resetIdleTimer));

      // Initialize the timer
      resetIdleTimer();

      // Cleanup event listeners and timer on unmount
      return () => {
        events.forEach(event => window.removeEventListener(event, resetIdleTimer));
        if (idleTimer) {
          clearTimeout(idleTimer);
        }
      };
    }
  }, [isLoggedIn, resetIdleTimer]);

  // Load initial auth state from localStorage
  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn') === 'true';
    const userPermission = localStorage.getItem('permissionLevel') || 'BASIC';
    setIsLoggedIn(loggedIn);
    setPermissionLevel(userPermission);
  }, []);

  const login = (permissions) => {
    localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('permissionLevel', permissions || 'BASIC');
    setIsLoggedIn(true);
    setPermissionLevel(permissions);
    resetIdleTimer(); // Start idle timeout after login
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, permissionLevel, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
