import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown, Modal, Button } from 'react-bootstrap';
import useAuth from './use_auth';
import NavBarMenu from './NavbarMenu';
import './assets/css/CustomNavbar.css';
import './ChatStyle.css';
import { v4 as uuidv4 } from 'uuid';
import ChatbotMessage from './ChatbotMessage';
import StyleChatbot from './StyleChatbot';
import TrainChatbot from './TrainChatbot';
import HistoryChatbot from './HistoryChatbot';
import LiveChatbot from './LiveChatbot';
import ChatbotNotifications from './ChatbotNotifications';
import { fetchWithSessionToken } from "./session_http_helper";

const CustomizeChatbot = () => {
    const [chatbotData, setChatbotData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [shop, setShop] = useState(localStorage.getItem('selectedShop') || 'test-brella-ai.myshopify.com');
    const [formData, setFormData] = useState({
        welcomeMessage: '',
        welcomeMessageOptions: '',
        contentPrompt: '',
        claimSupport: false
    });
    const [activeTab, setActiveTab] = useState('messages');
    const [welcomeMessages, setWelcomeMessages] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedWebsites, setSelectedWebsites] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isSearching, setIsSearching] = useState(false);
    const [baseUrl, setBaseUrl] = useState('');
    const [query, setQuery] = useState('');
    const [results, setResults] = useState({});
    const [discoveredUrls, setDiscoveredUrls] = useState([]);
    const [trainedUrls, setTrainedUrls] = useState([]);

    const handleQuery = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`https://api.brella-ai-shop.com/query?query=${encodeURIComponent(query)}&company_id=${encodeURIComponent(shop)}`);
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                setResults(data);
            }
        } catch (error) {
            alert('Error during querying:', error);
        }
    };

    const checkProgress = async (sessionId) => {
        try {
            const response = await fetch(`https://api.brella-ai-shop.com/progress?session_id=${encodeURIComponent(sessionId)}&company_id=${encodeURIComponent(shop)}`);
            const data = await response.json();
            setProgress((data.current / data.total) * 100);
            if (data.status === 'completed') {
                setSearchResults(data.subpages ? data.subpages.map((url, index) => ({ id: index, url })) : []);
                setIsSearching(false);
            } else if (data.status === 'cancelled') {
                setIsSearching(false);
            }
        } catch (error) {
            console.error('Error checking progress:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (isSearching) {
            interval = setInterval(() => {
                checkProgress(sessionStorage.getItem('sessionId'));
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isSearching]);

    const handleSearch = async (event) => {
        event.preventDefault();
        const url = event.target.elements[0].value;
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;        

        setIsSearching(true);
        setBaseUrl(formattedUrl);
        setSearchResults([]);
        setSelectedWebsites({});
        setSelectAll(false);
        setProgress(0);

        const sessionId = `${Date.now()}-${Math.random()}`;
        sessionStorage.setItem('sessionId', sessionId);

        try {
            const response = await fetch(`https://api.brella-ai-shop.com/scrape?url=${encodeURIComponent(formattedUrl)}&session_id=${sessionId}&company_id=${encodeURIComponent(shop)}`);
            const data = await response.json();
            if (data.error) {
                alert(data.error);
                setIsSearching(false);
            } else {
                console.log('Scraping started');
            }
        } catch (error) {
            alert('Error during scraping:', error);
            setIsSearching(false);
        }
    };

    const handleTrainUrls = async (selectedUrls) => {
        if (selectedUrls.length > 0) {
            // Make the first API call
            await updateVectorDB(selectedUrls);
    
            // Make the second API call with both trainedUrls and discoveredUrls
            const payload = {
                trainedUrls: selectedUrls
            };
    
            await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/trainedData/${shop}`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' },
            });

            setTrainedUrls(selectedUrls);
        }
    };
    
    const saveDiscoveredUrls = async () => {
        const selectedUrls = Object.keys(selectedWebsites).filter(id => selectedWebsites[id]).map(id => searchResults.find(site => site.id === parseInt(id)).url);
        const payload = {
            discoveredUrls: selectedUrls
        };
    
        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/trainedData/${shop}`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            setDiscoveredUrls(data.discoveredUrls);
        } catch (error) {
            alert('Error updating trained data:', error);
        }

    };

    const deleteDiscoveredUrls = async (urlsToDelete) => {
        const payload = {
            discoveredUrls: urlsToDelete
        };
    
        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/trainedData/${shop}`, {
                method: 'DELETE',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            setDiscoveredUrls(data.discoveredUrls);
        } catch (error) {
            alert('Error updating trained data:', error);
        }
    };    

    const deleteTrainedUrls = async (urlsToDelete) => {
        const payload = {
            trainedUrls: urlsToDelete
        };

        await deleteVectors(urlsToDelete);
    
        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/trainedData/${shop}`, {
                method: 'DELETE',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            setTrainedUrls(data.trainedUrls);
        } catch (error) {
            alert('Error updating trained data:', error);
        }
    };    


    const updateVectorDB = async (urlsToTrain) => {
        if (urlsToTrain.length === 0) {
            alert('No websites selected for vector update.');
            return;
        }

        setIsSearching(true);
        setProgress(0);

        try {
            const response = await fetch(`https://api.brella-ai-shop.com/update-vector-db?company_id=${encodeURIComponent(shop)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ urls: urlsToTrain, session_id: sessionStorage.getItem('sessionId') }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                console.log('Vector database update started');
            }
        } catch (error) {
            alert('Error during vector update:', error);
            setIsSearching(false);
        }
    };

    const deleteVectors = async (urlsToDelete) => {
        if (urlsToDelete.length === 0) {
            alert('No websites selected for vector deletion.');
            return;
        }

        try {
            const response = await fetch(`https://api.brella-ai-shop.com/delete-vectors`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ urls: urlsToDelete, company_id: shop }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                console.log('Vector deletion started');
            }
        } catch (error) {
            alert('Error during vector deletion:', error);
        }
    };

    const handleCancel = async () => {
        try {
            const response = await fetch(`https://api.brella-ai-shop.com/cancel?company_id=${encodeURIComponent(shop)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session_id: sessionStorage.getItem('sessionId') }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                console.log('Cancellation requested');
            }
        } catch (error) {
            alert('Error during cancellation:', error);
        }
    };

    const handleCheckboxChange = (id) => {
        console.log(id);
        setSelectedWebsites((prevSelectedWebsites) => ({
            ...prevSelectedWebsites,
            [id]: !prevSelectedWebsites[id],
        }));
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        const newSelectedWebsites = {};
        if (newSelectAll) {
            searchResults.forEach((site) => {
                newSelectedWebsites[site.id] = true;
            });
        }
        setSelectedWebsites(newSelectedWebsites);
    };

    const handleChangeConfigMessages = (event, index, type) => {
        const { value } = event.target;
        setWelcomeMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[index][type] = value;
            return newMessages;
        });
    };

    const handleAddRow = () => {
        setWelcomeMessages((prevMessages) => [
            ...prevMessages,
            { button_message: '', chat_state: 'ORDER' },
        ]);
    };

    const handleDeleteRow = (index) => {
        setWelcomeMessages((prevMessages) =>
            prevMessages.filter((_, i) => i !== index)
        );
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'messages':
                return <ChatbotMessage 
                            formData={formData} 
                            welcomeMessages={welcomeMessages} 
                            handleChange={handleChange} 
                            handleChangeConfigMessages={handleChangeConfigMessages} 
                            handleSubmit={handleSubmit} 
                            handleAddRow={handleAddRow} 
                            handleDeleteRow={handleDeleteRow}
                            isSaving={isSaving}
                            data={chatbotData}
                        />;
            case 'styling':
                return <StyleChatbot 
                            openAIConfig={openAIConfig} 
                            handleChangeConfig={handleChangeConfig} 
                            handleSubmit={handleSubmit} 
                            isSaving={isSaving} 
                        />;
            case 'history':
                return <HistoryChatbot
                            shop={shop}
                        />;
            case 'live':
                return <LiveChatbot
                            shop={shop}
                        />;
            case 'notifications':
                return <ChatbotNotifications
                            shop={shop}
                        />;
            case 'train':
                return <TrainChatbot
                            updateVectorDB={updateVectorDB}
                            deleteVectors={deleteVectors}
                            handleSearch={handleSearch} 
                            handleCancel={handleCancel} 
                            isSearching={isSearching} 
                            progress={progress} 
                            results={results}
                            searchResults={searchResults} 
                            handleCheckboxChange={handleCheckboxChange}
                            setQuery={setQuery}
                            handleQuery={handleQuery}
                            selectedWebsites={selectedWebsites} 
                            selectAll={selectAll} 
                            handleSelectAllChange={handleSelectAllChange} 
                            baseUrl={baseUrl}
                            companyId={shop}
                            handleTrainUrls={handleTrainUrls}
                            saveDiscoveredUrls={saveDiscoveredUrls}
                            discoveredUrls={discoveredUrls}
                            trainedUrls={trainedUrls}
                            deleteDiscoveredUrls={deleteDiscoveredUrls}
                            deleteTrainedUrls={deleteTrainedUrls}
                        />;
            default:
                return <ChatbotMessage />;
        }
    };

    const isLoggedIn = useAuth('ADMIN');

    const shopMapping = {
        'Jovi': 'getjovi.myshopify.com',
        'Signal': 'signalrelief.myshopify.com',
        'Brella': 'brella-ai',
        'BioLight': 'biolight-inc.myshopify.com',
        'Zac': 'zac_test'
    };

    const handleSelect = (shopName) => {
        const selectedShop = shopMapping[shopName];
        setShop(selectedShop);
        localStorage.setItem('selectedShop', selectedShop);
        window.location.reload();
    };

    const getShopName = (shopUrl) => {
        return Object.keys(shopMapping).find(key => shopMapping[key] === shopUrl);
    };

    const [openAIConfig, setOpenAIConfig] = useState({
        chat_container_width: '',
        chat_container_height: '',
        chat_toggle_button_width: '',
        chat_toggle_button_height: '',
        chat_toggle_button_color: '',
        chat_toggle_button_color_hover: '',
        chat_header_background_color: '',
        chat_input_background: '',
        chat_input_input_background: '',
        chat_input_button_color: '',
        chat_input_button_color_hover: '',
        chat_input_button_background: '',
        dot_background_color: '',
        user_message_color: '',
        user_message_background_color: '',
        server_button_background_color: '',
        server_button_background_color_hover: '',
        server_message_color: '',
        server_message_background_color: '',
        welcome_message_options: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 1000) {
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleClaimSupportChange = (e) => {
        const { checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            claimSupport: checked,
        }));
    };

    const handleChangeConfig = (e) => {
        const { name, value } = e.target;
        setOpenAIConfig(prevState => {
            const updatedConfig = {
                ...prevState,
                [name]: value,
            };
    
            for (const [key, value] of Object.entries(updatedConfig)) {
                const cssVarName = `--${key.replace(/_/g, '-')}`;
                document.documentElement.style.setProperty(cssVarName, value || '');
            }
    
            return updatedConfig;
        });
    };

    useEffect(() => {
        fetchWelcomeMessage();
        fetchTrainedData();

        const loadChatWidget = async (shop) => {
            const script = document.createElement('script');
            script.src = "https://brella-ai-chatwidget-01f675efcccd.herokuapp.com/ChatWidget.js";
            script.onload = () => {
                console.log("ChatWidget.js loaded successfully");
                window.initChatWidget('#chat-widget-container');
            };
            script.onerror = () => {
                console.error("Error loading ChatWidget.js");
            };
            document.body.appendChild(script);

            const companyIdScript = document.createElement('script');
            companyIdScript.type = "text/javascript";
            companyIdScript.text = `var companyId = '${shop}';`;
            document.body.appendChild(companyIdScript);

            const chatbotStylesheet = document.createElement('link');
            chatbotStylesheet.rel = 'stylesheet';
            chatbotStylesheet.href = 'https://brella-ai-chatwidget-01f675efcccd.herokuapp.com/ChatWidget.css';
            chatbotStylesheet.onload = () => {
                console.log("ChatWidget.css loaded successfully");
            };
            chatbotStylesheet.onerror = () => {
                console.error("Error loading ChatWidget.css");
            };
            document.head.appendChild(chatbotStylesheet);
        };

        loadChatWidget(shop);
    }, [shop]);

    const handleSubmit = async (e, iconImage, headerImage) => {
        e.preventDefault();
        setIsSaving(true);
        setSaveSuccess(false);
    
        const formDataToSend = new FormData();
    
        setFormData(formData => ({
            ...formData,
            companyId: shop
        }));

        const imageUrl = "https://d1f6rj4sne1926.cloudfront.net/";
    
        if (iconImage) {
            const iconImageUrl = imageUrl + shop + uuidv4() + ".png";
            formDataToSend.append('iconImage', iconImage);
            setFormData(formData => ({
                ...formData,
                chatbotIconImage: iconImageUrl
            }));
        }
    
        if (headerImage) {
            const headerImageUrl = imageUrl + shop + uuidv4() + ".png";
            formDataToSend.append('headerImage', headerImage);
            setFormData(formData => ({
                ...formData,
                chatbotHeaderImage: headerImageUrl
            }));
        }

        console.log(formData);
    
        const openAI = {
            ...formData,
            welcomeMessageOptions: welcomeMessages,
            openAIConfig: { ...openAIConfig },
        };
        formDataToSend.append('openAIString', JSON.stringify(openAI));
    
        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/company?companyId=${shop}`, {
                method: 'PUT',
                body: formDataToSend,
            });
    
            if (response.ok) {
                setSaveSuccess(true);
            } else {
                console.error('Error posting data:', response.statusText);
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    
        setIsSaving(false);
        fetchWelcomeMessage();

    };

    const fetchTrainedData = async () => {

        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/trainedData/${shop}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            setDiscoveredUrls(data.discoveredUrls);
            setTrainedUrls(data.trainedUrls);
        } catch (error) {
            console.error('Failed to fetch trained data:', error);
        }
    }

    const fetchWelcomeMessage = async () => {

        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/welcome?companyId=${shop}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setChatbotData(data);
            const { welcomeMessage, welcomeMessageOptions, openAIConfig, chatbotIconImage, chatbotHeaderImage, chatbotHeaderTitle, chatbotHeaderDescription, chatbotName, userName } = data;
    
            setFormData(prevFormData => ({
                ...prevFormData,
                welcomeMessage: welcomeMessage,
                welcomeMessageOptions: welcomeMessageOptions,
                contentPrompt: data.contentPrompt,
                claimSupport: data.claimSupport,
                chatbotHeaderTitle: chatbotHeaderTitle || '',
                chatbotHeaderDescription: chatbotHeaderDescription || '',
                chatbotName: chatbotName || '',
                userName: userName || ''
            }));

            setWelcomeMessages(welcomeMessageOptions);

            setOpenAIConfig({
                chat_container_width: openAIConfig?.chat_container_width || '400px',
                chat_container_height: openAIConfig?.chat_container_height || '500px',
                chat_toggle_button_width: openAIConfig?.chat_toggle_button_width || '50px',
                chat_toggle_button_height: openAIConfig?.chat_toggle_button_height || '50px',
                chat_toggle_button_color: openAIConfig?.chat_toggle_button_color || '#5a67d8',
                chat_toggle_button_color_hover: openAIConfig?.chat_toggle_button_color_hover || '#4c51bf',
                chat_header_background_color: openAIConfig?.chat_header_background_color || '#ffffff',
                server_button_background_color: openAIConfig?.server_button_background_color || '#4CAF50',
                server_button_background_color_hover: openAIConfig?.server_button_background_color_hover || '#4CAF50',
                chat_input_button_color: openAIConfig?.chat_input_button_color || '#5a67d8',
                chat_input_button_background: openAIConfig?.chat_input_button_background || '#f7f7f7',
                chat_input_button_color_hover: openAIConfig?.chat_input_button_color_hover || '#5a67d8',
                chat_input_button_background: openAIConfig?.chat_input_button_background || '#f7f7f7',
                dot_background_color: openAIConfig?.dot_background_color || '#5a67d8',
                user_message_color: openAIConfig?.user_message_color || '#5a67d8',
                user_message_background_color: openAIConfig?.user_message_background_color || '#ffffff',
                server_message_color: openAIConfig?.server_message_color || '#000000',
                server_message_background_color: openAIConfig?.server_message_background_color || '#f0f0f0',
                welcome_message_options: openAIConfig?.welcome_message_options || '#fff'
            });

            if (openAIConfig !== null && openAIConfig !== undefined) {
                for (const [key, value] of Object.entries(openAIConfig)) {
                    const cssVarName = `--${key.replace(/_/g, '-')}`;
                    document.documentElement.style.setProperty(cssVarName, value || '');
                }
            }

        } catch (error) {
            console.error('Failed to fetch welcome message:', error);
        }
    };

    return (
        <div>
            <NavBarMenu shouldLoadChatWidget={false} />
            <div className="main-content" style={{ paddingTop: '100px' }}>
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="header-title">
                                        Customize your Chat Bot
                                    </h1>
                                </div>
                                <div className="col-auto">
                                    <div>
                                        <DropdownButton id="dropdown-basic-button" title={getShopName(shop)} onSelect={handleSelect}>
                                            {Object.keys(shopMapping).map((shopName) => (
                                                <Dropdown.Item eventKey={shopName} key={shopName}>{shopName}</Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <ul className="nav nav-tabs nav-overflow header-tabs">
                                            <li className="nav-item active">
                                                <button
                                                    className={`nav-link ${activeTab === 'messages' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('messages')}
                                                >
                                                    Messages
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'styling' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('styling')}
                                                >
                                                    Styling
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'train' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('train')}
                                                >
                                                    Train Chat Bot
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'history' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('history')}
                                                >
                                                    Message History
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'live' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('live')}
                                                >
                                                    Live Chat
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('notifications')}
                                                >
                                                    Notifications
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderContent()}
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Welcome Message Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>This is where you set the greeting message for your chat widget.</p>
                            <p>For example, "Welcome to our service! How can we assist you?"</p>
                            <img src="/Welcome_Message.png" className="img-fluid" alt="Example chat window" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default CustomizeChatbot;
